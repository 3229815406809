var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"mx-4"},[_c('confirmation-dialog',{ref:"confirm"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},on:{"click:row":_vm.editUser},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('TableHeader',{attrs:{"show-create":_vm.currentUserRole === 'ADMIN' ? true : false,"search":_vm.search,"show-search":_vm.currentUserRole === 'ADMIN' ? true : false},on:{"update-search":_vm.updateSearch,"create":_vm.createUser}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-cog")]),_c('span',[_vm._v(_vm._s(_vm.$t('administration.title')))])],1)]},proxy:true},{key:"item.emailVerified",fn:function(ref){
var item = ref.item;
return [(item.emailVerified)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_vm._e(),(!item.emailVerified)?_c('div',[_c('v-tooltip',{attrs:{"right":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.disabled)?_c('v-icon',_vm._g({},on),[_vm._v("mdi-account-off")]):_c('v-icon',_vm._g({attrs:{"color":"warning"}},on),[_vm._v("mdi-account-clock")])]}}],null,true)},[(item.disabled)?_c('span',[_vm._v(_vm._s(_vm.$t('administration.accountDisabled')))]):_c('span',[_vm._v(_vm._s(_vm.$t('administration.accountPending')))])])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.currentUserRole === 'ADMIN')?_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","color":"primary","text":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteUser(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"item.creationTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.creationTime))+" ")]}},{key:"item.lastSignInTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.lastSignInTime))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }