<template>
  <v-toolbar flat>
    <v-row>
      <v-col :cols="calculateWidth()" class="mt-3">
        <v-toolbar-title>
          <slot></slot>
        </v-toolbar-title>
      </v-col>
      <v-col cols="6" class="mt-3">
        <v-row class="no-wrap justify-end">
          <v-text-field
            v-if="showSearch"
            v-model="searchVal"
            prepend-icon="mdi-magnify"
            :append-icon="searchVal ? 'mdi-close' : ''"
            @click:append="searchVal = ''"
            :label="$t('common.search')"
            single-line
          ></v-text-field>
          <div class="ml-4">
            <v-btn v-if="showCreate" color="primary" text outlined @click="$emit('create')">
              <v-icon small>mdi-plus</v-icon>
              <span v-t="'common.create'"></span>
            </v-btn>
            <slot name="append"></slot>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-toolbar>
</template>

<script>
export default {
  name: 'TableHeader',
  props: {
    search: {
      type: String,
      required: false
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: true
    },
    showCreate: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    searchVal: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit('update-search', value);
      }
    }
  },
  methods: {
    calculateWidth() {
      let width = 12;
      width -= this.showSearch || this.showCreate ? 6 : 0; // Add columns for search field and create button
      return width;
    }
  }
};
</script>
<style scoped>
.no-wrap {
  flex-wrap: nowrap;
}
</style>
