




















































import { Component, Vue } from 'vue-property-decorator';
import TableHeader from '../../components/tableTemplates/TableHeader.vue';
import authService, { AuthService } from '../../services/AuthService';
import { FirebaseAuthService } from '@/services/firebase/FirebaseAuthService';
import { ERole } from '@/model/ERole';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';

@Component({
  components: {
    TableHeader,
    ConfirmationDialog
  }
})
export default class Administration extends Vue {
  routes: any = [];
  search = '';
  items: any = [];
  headers = [
    { text: 'Name', value: 'displayName' },
    { text: 'E-Mail', value: 'email' },
    { text: 'Rolle', value: 'role' },
    { text: 'Status', value: 'emailVerified' },
    { text: 'Registriert am', value: 'creationTime' },
    { text: 'Zuletzt angemeldet am', value: 'lastSignInTime' },
    { text: 'Aktionen', value: 'actions' }
  ];
  currentUser: any | null = null;
  role: ERole[] = [];
  currentUserRole: ERole | null = null;
  authService = new AuthService();

  async created() {
    await this.getCurrentUser();
    if (this.currentUserRole === ERole.EDITOR) {
      this.headers.pop();
      this.items = [await authService.getUser(this.currentUser.uid)];
    } else if (this.currentUserRole === ERole.ADMIN) {
      this.items = await authService.getUsers();
    }
  }

  async getCurrentUser() {
    const firebaseUser = await FirebaseAuthService.currentUser;
    if (!firebaseUser) {
      return;
    }
    this.currentUser = {
      uid: firebaseUser.uid
    };
    this.currentUserRole = await FirebaseAuthService.getCurrentUserRoles();
  }

  editUser(user: any) {
    this.$router.push({ name: 'User', params: { id: user.uid } });
  }

  createUser() {
    this.$router.push({ name: 'User' });
  }

  updateSearch(search: string) {
    this.search = search;
  }

  async deleteUser(user: any) {
    const confirm = await (this.$refs.confirm as any).open(
      this.$t('administration.deleteUser'),
      this.$t('administration.deleteUserConfirmation')
    );
    if (confirm === true) {
      await this.authService.deleteUser(user.uid);
      this.items = await authService.getUsers();
      this.$showMessage(this.$t('administration.userDeleted'));
    }
  }
}
