

























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ConfirmationDialog extends Vue {
  dialog = false;
  resolve: any | null = null;
  reject: any | null = null;
  message: string | null = null;
  title: string | null = null;
  options = {
    width: 600,
    zIndex: 200
  };

  open(title: string, message: string, options: any) {
    this.dialog = true;
    this.title = title;
    this.message = message;
    this.options = Object.assign(this.options, options);
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
  agree() {
    this.resolve && this.resolve(true);
    this.dialog = false;
  }
  cancel() {
    this.resolve(false);
    this.dialog = false;
  }
}
